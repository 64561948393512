import React from 'react';

// components
import Layout from '../components/layout';
import SEO from '../components/seo';
import VendorPartnerLogos from '../components/vendor-partner-logos';
import MissionStatement from '../components/mission-statement';
import HomePageMemberPrograms from '../components/home-page-member-programs';
import CheesePrices from '../components/cheese-prices';

// styled components
import ChalkboardStyles from '../components/styles/chalkboard-styles';
import JoinNowStyles from '../components/styles/join-now-styles';

// images
// TODO: use gatsby-image for better loading
import DotLine from '../images/dot-line.svg';
import ClipboardCaesar from '../images/clipboard-caesar.svg';
import RSVPConference from '../data/2025-RSVP-Conference-FINAL.pdf';

const IndexPage = () => (
    <Layout>
        <SEO title="Home" />
        <JoinNowStyles>
            <h1>Become a member</h1>
            <p>Join us today and add your voice to our growing numbers</p>
            <a
                className="JoinNow__button"
                style={{ textDecoration: 'none' }}
                href="/join-now"
            >
                Join Today
            </a>
            <div className="ClipboardCaesar__container">
                <img className="DottedLine" src={DotLine} alt="Dotted Line" />
                <img src={ClipboardCaesar} alt="Clipboard Caesar" />
            </div>
        </JoinNowStyles>
        <ChalkboardStyles>
            <p>
                <p>
                    RSVP for the 2025 IOLCF Conference today!
                    <br />
                    <br />
                    April 27th, 2025
                </p>
                <a
                    href={RSVPConference}
                    target="_blank"
                    type="application/pdf"
                    rel="noopener noreferrer"
                    download
                >
                    Click Here to RSVP
                </a>
            </p>
        </ChalkboardStyles>
        <VendorPartnerLogos />
        <MissionStatement />
        <HomePageMemberPrograms />
        <CheesePrices />
    </Layout>
);

export default IndexPage;
